import React from 'react';

const Error404 = () => (
    <div>
        <h1>Error 404</h1>
        <h2>Oops, the page you are looking for was not found.</h2>
    </div>
);

export default Error404;
