import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { addBodyMinWidth400, removeBodyMinWidth400 } from '../../../Helper/Utils';
import "./RecipeBox.sass";

const defaultRecipes = [
    {
        recipeName: "Spaghetti",
        ingredients: ["Noodles", "Tomato Sauce", "(Optional) Meatballs"]
    },
    {
        recipeName: "Onion Pie",
        ingredients: ["Onions", "Flour"]
    }
];

const getRecipesFromStorage = () => {
    if (typeof Storage === "undefined") {
        console.log('No local storage support!');
    }

    // Retrieve the object from storage
    let retrievedObject = localStorage.getItem('Recipes');
    // If retrievedObject doesn't exist in the local storage:
    if (!retrievedObject) {
        // Store in the Local Storage:
        localStorage.setItem('Recipes', JSON.stringify(defaultRecipes));
        retrievedObject = defaultRecipes;
    }

    try {
        retrievedObject = JSON.parse(retrievedObject);
        if (typeof retrievedObject  !== 'object') {
            throw new Error;
        }
    } catch (err) {
        if (!localStorage.getItem('Recipes')) {
            console.log('Error with local storage:', err);
        }
        // Store in the Local Storage:
        localStorage.setItem('Recipes', JSON.stringify(defaultRecipes));
        retrievedObject = defaultRecipes;
    }
    return retrievedObject;
};

const Recipe = ({ recipes, updateStateAndLocalStorage, index }) => {
    const [show, setShow] = useState(false);

    const nameClick = () => {
        setShow(!show);
    }

    const deleteRecipe = (index) => {
        let newRecipes = [...recipes];
        newRecipes.splice(index, 1);
        updateStateAndLocalStorage(newRecipes);
    }

    let j = 0;
    const recipeIngredients = recipes[index].ingredients.map(ingredient => <li key={j++}>{ingredient}</li>);

    const recipeDetails = (
        <div>
            <ul>
                {recipeIngredients}
            </ul>
            <div>
                <RecipeModal type='Edit' recipes={recipes} updateStateAndLocalStorage={updateStateAndLocalStorage} index={index} />
                <Button className="delete" variant="danger" onClick={() => deleteRecipe(index)}>Delete</Button>
            </div>
        </div>
    );

    return (
        <div>
            <h1 onClick={() => nameClick()} className="inline-block">
                <u>{recipes[index].recipeName}</u> <i className={`fa fa-caret-${show ? 'down' : 'right' }`} aria-hidden="true"></i>
            </h1>
            {show && recipeDetails}
        </div>
    );
}

const RecipeModal = ({ type, recipes, index, updateStateAndLocalStorage }) => {
    const [buttonName, setButtonName] = useState('');
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState('');
    const [recipeName, setRecipeName] = useState('');
    const [ingredients, setIngredients] = useState([]);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (title || buttonName) {
            return;
        }
        if (type === 'Add') {
            setTitle('Add Recipe');
            setButtonName('Add a recipe');
        } else if (type === 'Edit') {
            setTitle('Edit Recipe');
            setButtonName('Edit');
        }
    });

    const handleClose = () => {
        setShow(false);
        setIngredients([]);
        setRecipeName('');
    }

    const handleShow = () => setShow(true);

    const handleChange = (e) => {
        if (e.target.name === 'recipeName') {
            setRecipeName(e.target.value);
        } else if (e.target.name === 'ingredients') {
            setIngredients(e.target.value.split(','));
        }
    }

    const onEnter = () => {
        if (type === 'Edit') {
            setRecipeName(recipes[index].recipeName);
            setIngredients(recipes[index].ingredients);
        }
    }

    const saveRecipe = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            const recipeClone = [...recipes]; // Κλώνος του object με τις συνταγές
            const newRecipe = {
                recipeName,
                ingredients
            };
            if (type === 'Add') {
                recipeClone.push(newRecipe);
            } else if (type === 'Edit') {
                recipeClone.splice(index, 1, newRecipe); // replacing the recipe item.
            }
            updateStateAndLocalStorage(recipeClone);
            setRecipeName('');
            setIngredients('');
            handleClose();
        }

        setValidated(true);
    }

    return (
        <div style={{display: 'inline'}}>
            <Button variant="primary" onClick={() => handleShow()}>{buttonName}</Button>

            <Modal show={show} onHide={() => handleClose()} onEnter={() => onEnter()}>
                <Form noValidate validated={validated} onSubmit={saveRecipe}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label><strong>Recipe name</strong></Form.Label>
                            <Form.Control
                                type="text"
                                name='recipeName'
                                value={recipeName}
                                placeholder="Recipe name"
                                onChange={(e) => handleChange(e)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">Please write a recipe name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label><strong>Ingredients</strong></Form.Label>
                            <Form.Control
                                type="text"
                                name='ingredients'
                                value={ingredients}
                                placeholder="Enter the ingredients, separated by commas."
                                onChange={(e) => handleChange(e)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">Please write at least one ingredient.</Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={type === 'Add' ? 'primary' : 'light'} type="submit">{buttonName}</Button>
                        <Button onClick={() => handleClose()}>Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}

const RecipeBox = () => {
    const [recipes, setRecipes] = useState(getRecipesFromStorage());

    const updateStateAndLocalStorage = (arr) => {
        localStorage.setItem('Recipes', JSON.stringify(arr));
        setRecipes(arr);
    }

    //     if (window.matchMedia("(min-width: 400px)").matches) {
    let i = 0;
    const recipesList = recipes.map((recipe, index) => {
        return (
            <Recipe
                key={i++}
                recipes={recipes}
                index={index}
                updateStateAndLocalStorage={updateStateAndLocalStorage}
            />
        );
    });

    React.useEffect(() => {
        document.title = 'Recipe Box';
        addBodyMinWidth400();

        return () => {
            removeBodyMinWidth400();
        };
    });

    return (
        <div id="recipe-box">
            <h1 className="page-title">Recipe Box</h1>
            <h4>Recipes are saved even if the page is refreshed.</h4>
            <Card>
                <Card.Body>
                    {recipesList}
                </Card.Body>
            </Card>
            <RecipeModal type='Add' recipes={recipes} updateStateAndLocalStorage={updateStateAndLocalStorage} />
        </div>
    );
}

export default RecipeBox;
