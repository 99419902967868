import React from 'react';
import marked from 'marked';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import "./MarkdownPreviewer.sass";
import { addBodyMinWidth400, removeBodyMinWidth400 } from '../../../Helper/Utils';

class TextConversion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: 'Heading\n=======\n\nSub-heading\n-----------\n\n\n### Another deeper heading\n\n\nParagraphs are separated\nby a blank line.\n\nLeave 2 spaces at the end of a line to do a \nline break\n\nText attributes *italic*, **bold**, `monospace`, ~~strikethrough~~ .\n\nShopping list:\n\n  * apples\n  * oranges\n  * pears\n\nNumbered list:\n\n  1. apples\n  2. oranges\n  3. pears\n\n\n *[Achilleas Papakonstantinou](https://freecodecamp.com/AchiPapakon)*',
        };
    }
    rawMarkup() {
        var rawMarkup = marked(this.state.text.toString(), {sanitize: true});
        return { __html: rawMarkup };
    }
    changeText(event) {
        this.setState({
            text: event.target.value
        });
    }
    render() {
        return (
            <Container>
                <Row>
                    <Col sm={6}>
                        <Card className="card-input">
                            {/* <Card.Body> */}
                                <textarea className="text-input form-control" onChange={(e) => this.changeText(e)} value={this.state.text}></textarea>
                            {/* </Card.Body> */}
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card>
                            <Card.Body className="card-output">
                                <span className="text-output" dangerouslySetInnerHTML={this.rawMarkup()} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const MarkdownPreviewer = () => {
    React.useEffect(() => {
        document.title = `Markdown Previewer`;
        addBodyMinWidth400();

        return () => {
            removeBodyMinWidth400();
        };
    });

    return (
        <div id="markdown-previewer">
            <h1 className="page-title">Markdown Previewer</h1>
            <TextConversion />
        </div>
    );
}

export default MarkdownPreviewer;
