import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import "./PortfolioContainer.sass";

const urlPathParent = "portfolio";

const portfolioArray = [
    {
        relativeUrl: `/${urlPathParent}/janedoe`,
        img: `/images/Portfolio/janedoe.jpg`,
        title: `Janedoe Website`,
        newTab: true
    },
    {
        relativeUrl: `/${urlPathParent}/property-management`,
        img: `/images/Portfolio/propertyManagement.jpg`,
        title: `Property Management (MySQL)`,
        newTab: true
    },
    {
        relativeUrl: `/${urlPathParent}/game-of-life`,
        img: `/images/Portfolio/gameOfLife.png`,
        title: `Game of Life`,
    },
    {
        relativeUrl: `/${urlPathParent}/recipe-box`,
        img: `/images/Portfolio/recipeBox.jpg`,
        title: `Recipe Box`,
    },
	{
        relativeUrl: `/${urlPathParent}/markdown-previewer`,
        img: `/images/Portfolio/markdownPreviewer.jpg`,
        title: `Markdown Previewer`,
    },
    {
        relativeUrl: `/${urlPathParent}/fibonacci-clock`,
        img: `/images/Portfolio/fibonacciClock.png`,
        title: `Fibonacci Clock`,
    },
    {
        relativeUrl: `/${urlPathParent}/simon-game`,
        img: `/images/Portfolio/simonGame.jpg`,
        title: `Simon game`,
    },
    {
        relativeUrl: `/${urlPathParent}/tic-tac-toe`,
        img: `/images/Portfolio/ticTacToe.jpg`,
        title: `Tic Tac Toe`,
    },
    {
        relativeUrl: `/${urlPathParent}/pomodoro-clock`,
        img: `/images/Portfolio/pomodoroClock.jpg`,
        title: `Pomodoro Clock`,
    },
    {
        relativeUrl: `/${urlPathParent}/calculator`,
        img: `/images/Portfolio/calculator.jpg`,
        title: `Calculator`,
    },
    {
        relativeUrl: `/${urlPathParent}/twitch-viewer`,
        img: `/images/Portfolio/twitchViewer.jpg`,
        title: `Twitch Viewer`,
    },
    {
        relativeUrl: `/${urlPathParent}/wikipedia-viewer`,
        img: `/images/Portfolio/wikipediaViewer.jpg`,
        title: `Wikipedia Viewer`,
    },
    {
        relativeUrl: `/${urlPathParent}/local-weather`,
        img: `/images/Portfolio/localWeather.jpg`,
        title: `Local Weather`,
    },
    {
        relativeUrl: `/${urlPathParent}/random-quotes`,
        img: `/images/Portfolio/randomQuotes.jpg`,
        title: `Random Quotes`,
    }
];

const PortfolioElement = ({ newTab, link, img, title }) => {
    const anchorNewTab = newTab ? "_blank" : "_self";
    return (
        <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} lg={{ span: 4, offset: 0 }}>
            <div style={{ backgroundColor: '#000', border: '1px solid #ddd', borderRadius: '4px', padding: '4px', marginBottom: '20px' }}>
                <a href={link} target={anchorNewTab}>
                    <Image fluid src={img} />
                    <div className="img-captions">{title}</div>
                </a>
            </div>
        </Col>
    );
}

const PortfolioItems = () => {
    let i = 0;
    const thumbnails = portfolioArray.map((portfolioItem) => <PortfolioElement key={i++} link={portfolioItem.relativeUrl} img={portfolioItem.img} title={portfolioItem.title} newTab={portfolioItem.newTab} />);
    return <Row>{thumbnails}</Row>;
}

const PortfolioContainer = () => (
    <div id="portfolioContainer">
        <div id="Home" className="sections" style={{ backgroundImage: "url(/images/Portfolio/backgrounds/home.jpg)" }}>
            <div className="text-center">
                <h1 className="home-title">Achilleas<br />Papakonstantinou</h1>
                <h2 className="home-subtitle">My personal portfolio page.</h2>
                <hr />
            </div>
        </div>

        <div id="About" className="sections" style={{ backgroundImage: "url(/images/Portfolio/backgrounds/about.jpg)" }}>
            <Container>
                <Row>
                    <Col xs={{ order: 2 }} sm={{ order: 2 }} md={{ order: 1 }} style={{ maxWidth: '400px' }}>
                        <Card bg="light" style={{ marginBottom: '20px' }}>
                            <Card.Body>
                                <h1 className="section-title">About me</h1>
                            </Card.Body>
                        </Card>
                        <Card bg="light">
                            <Card.Body style={{ fontSize: '18px' }}>
                                <Card.Text>I am a Fullstack Developer based in Nice, France.</Card.Text>
                                <Card.Text>I have diplomas in Electrical & Computer Engineering MSc, BSc with a second MSc in Biostatistics/Bioinformatics.</Card.Text>
                                <Card.Text>I love coding and mathematics!</Card.Text>
                                <div>I have built this website using:</div>
                                <ul>
                                    <li>NodeJS/Express</li>
                                    <li>React</li>
                                    <li>Bootstrap</li>
                                    <li>HTML/SASS/JavaScript</li>
                                    <li>MySQL</li>
                                    <li>Nginx</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={{ order: 1, offset: 2 }} sm={{ order: 1 }} md={{ order: 2, offset: 2 }} style={{ maxWidth: '400px' }} >
                        <img src="images/profilePic.jpg" alt="profile_picture" style={{ borderRadius:'50%', maxWidth:'200px', marginBottom: '10px' }} />
                    </Col>
                </Row>
            </Container>
        </div>

        <div id="Portfolio" className="sections" style={{ backgroundImage: "url(/images/Portfolio/backgrounds/portfolio.jpg)" }}>
            <Container fluid style={{maxWidth:'1200px', marginLeft:'auto', marginRight:'auto'}}>
                <Card bg="light" className="text-center" style={{ marginBottom: '20px' }}>
                    <Card.Body>
                        <h1 className="section-title">My web development portfolio</h1>
                        <h2 className="section-subtitle">(NodeJS/React)</h2>
                        <Card.Text style={{ fontSize: '18px' }}>This is some of my work (most recent first):</Card.Text>
                    </Card.Body>
                </Card>
                <PortfolioItems />
            </Container>
        </div>

        <div id="Contact" className="sections" style={{ backgroundImage: "url(/images/Portfolio/backgrounds/contact.jpg)" }}>
            <div style={{marginLeft:'10%', maxWidth:'500px'}}>
                <h1 className="section-title" style={{ color: '#E1E1E1', textAlign: 'left' }}>Contact me</h1>
                <br />
                <Button variant="light">
                    <span className="fa-stack">
                    <i className="fa fa-twitter fa-2x fa-stack-1x" style={{color:'#4099FF'}}></i>
                    <i className="fa fa-ban fa-stack-2x text-danger"></i>
                    </span>
                </Button>
                {' '}
                <Button variant="light">
                    <span className="fa-stack">
                    <i className="fa fa-facebook fa-2x fa-stack-1x" style={{color:'#3B5998'}}></i>
                    <i className="fa fa-ban fa-stack-2x text-danger"></i>
                    </span>
                </Button>
                {' '}
                <Button variant="light" href="https://gr.linkedin.com/in/achilleas-papakonstantinou-aa6447113" target="_blank" rel="noopener">
                    <i className="fa fa-linkedin fa-2x" style={{ color:'#007bb5' }}></i>
                </Button>
                <br />
                <br />
                <p style={{ color:'#fff', fontSize:'150%' }}>Papakonstantinou.Achilleas (at) gmail.com</p>
                <hr />
                <p style={{color:'#fff', fontSize:'150%'}}>Bonus portfolio:
                    <br />
                    <Button variant="light" href="https://github.com/AchiPapakon?tab=repositories&type=source" target="_blank" rel="noopener">
                        <i className="fa fa-github fa-2x" style={{color:'#24292e'}}></i>
                    </Button>
                    {' '}
                    <Button variant="light" href="https://freecodecamp.com/achipapakon" target="_blank" rel="noopener">
                        <i className="fa fa-free-code-camp fa-2x" style={{color:'#006400'}}></i>
                    </Button>
                </p>
            </div>
        </div>
    </div>
);

export default PortfolioContainer;
