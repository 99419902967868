import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import '../../../Helper/HoverEffect.sass';
import './AchNavbar.sass';

const AchNavbar = () => (
    <Container>
        <Navbar collapseOnSelect fixed="top" expand="md" bg="dark" variant="dark" className="hover-effect-1 smaller-responsive-button">
                <Navbar.Brand href="/#Home">Achilleas Papakonstantinou</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto" />
                    <Nav>
                        <Nav.Link eventKey={1} href="/#Home">Home</Nav.Link>
                        <Nav.Link eventKey={2} href="/#About">About</Nav.Link>
                        <Nav.Link eventKey={3} href="/#Portfolio">Portfolio</Nav.Link>
                        <Nav.Link eventKey={4} href="/#Contact">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
        </Navbar>
    </Container>
);

export default AchNavbar;
