export const addBodyMinWidth550 = () => {
    document.body.classList.add('min-width-550');
}

export const removeBodyMinWidth550 = () => {
    document.body.classList.remove('min-width-550');
}

export const addBodyMinWidth400 = () => {
    document.body.classList.add('min-width-400');
}

export const removeBodyMinWidth400 = () => {
    document.body.classList.remove('min-width-400');
}
