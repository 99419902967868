import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import { addBodyMinWidth400, removeBodyMinWidth400 } from '../../../Helper/Utils';
import "./PomodoroClock.sass";

class MainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "session", // "session" or "break"
            progressBarLeft: 0,
            progressBarRight: 0,
            isRunning: false,
            isPaused: true
        };

        // Retrieve the object from storage
        let retrievedObject = localStorage.getItem('pomodoroClock');
        const obj = {
            session: {
                hours: '00',
                minutes: '25',
                seconds:'00'
            },
            break: {
                hours: '00',
                minutes: '05',
                seconds:'00'
            }
        };
        // If retrievedObject doesn't exist in the local storage:
        if (!retrievedObject) {
            // Store in the Local Storage:
            localStorage.setItem('pomodoroClock', JSON.stringify(obj));
            retrievedObject = obj;
        }
        try {
            retrievedObject = JSON.parse(retrievedObject);
            if (typeof(retrievedObject) !== 'object') throw new Error;
        } catch (err) {
            if (!localStorage.getItem('pomodoroClock')) console.log('Error with local storage:', err);
            // Store in the Local Storage:
            localStorage.setItem('pomodoroClock', JSON.stringify(obj));
            retrievedObject = obj;
        } finally {
            this.state.session = retrievedObject.session;
            this.state.break = retrievedObject.break;
        }
        this.switchModes = this.switchModes.bind(this);
    }

    handleClick(plusMinus, whichOne) {
        function saveInLocalStorage(in_mode, in_obj) {
            // Handle local storage:
            let retrievedObject = localStorage.getItem('pomodoroClock');
            if (!retrievedObject) console.log('Error with local storage:', err);
            retrievedObject = JSON.parse(retrievedObject);
            if (typeof(retrievedObject) !== 'object') throw new Error;
            retrievedObject[in_mode] = in_obj;
            localStorage.setItem('pomodoroClock', JSON.stringify(retrievedObject));
        }

        if (whichOne === "hours") {
            const mode = this.state.mode;
            let hours = parseInt(this.state[mode].hours);
            plusMinus === "plus" ? hours++ : hours--;
            hours = hours.toString();
            if (hours < 10) hours = `0${hours}`;
            // Deep copy the appropriate object ('session' or 'break')
            const obj = JSON.parse(JSON.stringify(this.state[mode]));
            obj.hours = hours;
            if (hours >= 0 && hours < 100) {
                this.setState({ [mode]: obj });
                saveInLocalStorage(mode, obj);
            }
        } else if (whichOne === "minutes" || whichOne === "seconds") {
            const mode = this.state.mode;
            let terms = parseInt(this.state[mode][whichOne]);
            plusMinus === "plus" ? terms += 5 : terms -= 5;
            terms = terms.toString();
            if (terms < 10) terms = `0${terms}`;
            // Deep copy the appropriate object ('session' or 'break')
            const obj = JSON.parse(JSON.stringify(this.state[mode]));
            obj[whichOne] = terms;
            if (terms >= 0 && terms < 60) {
                this.setState({ [mode]: obj });
                saveInLocalStorage(mode, obj);
            }
        }
    }

    btnBeginClick(input) {
        if (input !== "begin" && input !== "pause" && input !== "reset") {
            console.log(`Wrong input at 'btnBeginClick'`);
            return;
        }
        this.setState({ mode: "session"});
        let totalSession = this.state.session.hours * 3600 + this.state.session.minutes * 60 + this.state.session.seconds * 1;  // se deuterolepta
        let currentSession = 0;
        let totalBreak = this.state.break.hours * 3600 + this.state.break.minutes * 60 + this.state.break.seconds * 1;  // se deuterolepta
        let currentBreak = totalBreak;

        // Keep the values for reset:
        if (!this.state.isRunning) {
            this.setState({
                total: {
                    session: {
                        hours: this.state.session.hours,
                        minutes: this.state.session.minutes,
                        seconds: this.state.session.seconds
                    },
                    break: {
                        hours: this.state.break.hours,
                        minutes: this.state.break.minutes,
                        seconds: this.state.break.seconds
                    }
                }
            });
        }

        let currentTime;
        this.tick = () => {
            this.setState({ isRunning: true });
            if (this.state.mode === "session") {
                currentSession++;
                const percentage = currentSession / totalSession * 100;
                this.setState({ progressBarLeft: percentage });

                currentTime = totalSession - currentSession;
            } else {
                currentBreak--;
                const percentage = currentBreak / totalBreak * 100;
                this.setState({ progressBarLeft: percentage });
                this.setState({ progressBarRight: 100 - percentage });

                currentTime = currentBreak;
            }

            // Change the display:
            const obj = JSON.parse(JSON.stringify(this.state[this.state.mode]));
            if (currentTime >= 3600) {
                let hours = Math.floor(currentTime / 3600);
                hours = hours.toString();
                if (hours < 10) hours = `0${hours}`;
                obj.hours = hours;
                currentTime -= 3600 * hours;
            } else {
                obj.hours = '00';
            }
            if (currentTime >= 60) {
                let minutes = Math.floor(currentTime / 60);
                minutes = minutes.toString();
                if (minutes < 10) minutes = `0${minutes}`;
                obj.minutes = minutes;
                currentTime -= 60;
            } else {
                obj.minutes = '00';
            }
            let seconds = currentTime % 60;
            seconds = seconds.toString();
            if (seconds < 10) seconds = `0${seconds}`;
            obj.seconds = seconds;

            this.setState({ [this.state.mode]: obj });

            if (this.state.progressBarLeft <= 0) {
                this.switchModes();
                currentSession = 0;
                this.state.birdSound.play();
                setTimeout(() => {
                    this.state.birdSound.play();
                }, 600);
            } else if (this.state.progressBarLeft >= 100) {
                this.switchModes();
                currentBreak = totalBreak;
                this.state.birdSound.play();
            }
        }

        if (input === "begin") {
            this.timer1 = setInterval(
                () => this.tick(), 1000
            );
            this.setState({
                isPaused: false
            });
        } else if (input === "pause") {
            clearInterval(this.timer1);
            this.setState({ isPaused: true });
        } else if (input === "reset") {
            clearInterval(this.timer1);
            this.setState({
                isRunning: false,
                isPaused: true
            });
            this.setState({
                session: this.state.total.session,
                break: this.state.total.break,
                progressBarLeft: 0,
                progressBarRight: 0
            });
        }
    }

    componentDidMount() {
        this.setState({
            birdSound: new Audio('/audio/pomodoroBeep.wav')
        })
    }

    componentWillUnmount() {
        clearInterval(this.timer1);
        this.setState({
            isRunning: false,
            birdSound: null
        });
    }

    switchModes() {
        let mode;
        if (this.state.mode === "session") mode = "break";
        else mode = "session";
        this.setState({ mode });
    }

    render() {
        const changeRadioButtons = () => {
            this.switchModes();
        };
        return (
            <Container>
                <Row>
                    <Col xs={12} lg={{ span: 6, offset: 3}} className="parent-1">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={6}>
                                        <div className="panel">
                                            <Button variant="light" className="adjust-left" disabled={this.state.isRunning} onClick={() => this.handleClick("plus", "hours")}><i className="fa fa-plus fa-2x"></i></Button>
                                            <Button variant="light" className="adjust-middle" disabled={this.state.isRunning} onClick={() => this.handleClick("plus", "minutes")}><i className="fa fa-plus fa-2x"></i></Button>
                                            <Button variant="light" className="adjust-right" disabled={this.state.isRunning} onClick={() => this.handleClick("plus", "seconds")}><i className="fa fa-plus fa-2x"></i></Button>
                                            <div className="session-clock">
                                                {`${this.state[this.state.mode].hours}:${this.state[this.state.mode].minutes}:${this.state[this.state.mode].seconds}`}
                                            </div>
                                            <Button variant="light" className="adjust-left" disabled={this.state.isRunning} onClick={() => this.handleClick("minus", "hours")}><i className="fa fa-minus fa-2x"></i></Button>
                                            <Button variant="light" className="adjust-middle" disabled={this.state.isRunning} onClick={() => this.handleClick("minus", "minutes")}><i className="fa fa-minus fa-2x"></i></Button>
                                            <Button variant="light" className="adjust-right" disabled={this.state.isRunning} onClick={() => this.handleClick("minus", "seconds")}><i className="fa fa-minus fa-2x"></i></Button>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="controls">
                                            <Form>
                                                <Form.Group>
                                                    <Form.Check type="radio" label="Session" name="radioGroup" id="session-radio-button" disabled={this.state.isRunning} checked={this.state.mode === "session"} onChange={changeRadioButtons} />
                                                    <Form.Check type="radio" label="Break" name="radioGroup" id="break-radio-button" disabled={this.state.isRunning} checked={this.state.mode === "break"} onChange={changeRadioButtons} />
                                                </Form.Group>
                                                <Form.Group>
                                                    {this.state.isPaused ?
                                                        <Button variant="primary" onClick={() => this.btnBeginClick("begin")}>Begin</Button> :
                                                        <Button variant="warning" onClick={() => this.btnBeginClick("pause")}>Pause</Button>
                                                    }
                                                    <Button variant="danger" onClick={() => this.btnBeginClick("reset")} style={{ marginLeft: '5px' }}>Reset</Button>
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                <ProgressBar>
                                    <ProgressBar variant="success" now={this.state.progressBarLeft} key={1} />
                                    <ProgressBar variant="danger" now={this.state.progressBarRight} key={2} />
                                </ProgressBar>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const PomodoroClock = () => {
    React.useEffect(() => {
        document.title = 'Pomodoro Clock';
        addBodyMinWidth400();

        return () => {
            removeBodyMinWidth400();
        };
    });

    return (
        <div id="pomodoro-clock">
            <h1 className="page-title">Pomodoro Clock</h1>
            <MainComponent />
        </div>
    );
}

export default PomodoroClock;
