import React from 'react';
import Card from 'react-bootstrap/Card';
import { addBodyMinWidth550, removeBodyMinWidth550 } from '../../../Helper/Utils';
import "./FibonacciClock.sass";

const side = 68;
const a = `${side}px`;
const a2 = `${2 * side}px`;
const a3 = `${3 * side}px`;
const a5 = `${5 * side}px`;
const b = `3px`;

const styles = {
    title: {
        margin: '50px auto 20px',
        maxWidth: `${side * (5 + 3)}px`,
        textAlign: 'center',
        color: 'black'
    },
    clock: {
        margin: '0 auto 10px',
        maxWidth: `${side * (5 + 3)}px`,
        textAlign: 'center'
    },
    wellExplanation: {
        margin: `${side * 5 + 20 * 2}px auto 20px`,
        maxWidth: `${side * (5 + 3)}px`,
    }
}

const getColor = (in_state, default_color) => {
    if (in_state === 'hours') {
        return 'red';
    } else if (in_state === 'minutes') {
        return 'green';
    } else if (in_state === 'both') {
        return 'blue';
    } else {
        return default_color;
    }
}

class Clock extends React.Component {
    constructor(props) {
        super(props);
        const _backColor = 'white';
        this.state = {
            _1_1: _backColor,
            _1_2: _backColor,
            _2: _backColor,
            _3: _backColor,
            _5: _backColor
        };
    }

    tick() {
        const _backColor = 'white';
        const curDate = new Date();
        let curHours = curDate.getHours();
        let curMinutes = curDate.getMinutes();

        let _5 = _backColor;
        let _3 = _backColor;
        let _2 = _backColor;
        let _1_1 = _backColor;
        let _1_2 = _backColor;

        // Hours:
        if (curHours > 12) {
            curHours -= 12;
        }
        if (curHours - 5 >= 0) {
            _5 = 'hours';
            curHours -= 5;
        }
        if (curHours - 3 >= 0) {
            _3 = 'hours';
            curHours -= 3;
        }
        if (curHours - 2 >= 0) {
            _2 = 'hours';
            curHours -= 2;
        }
        if (curHours - 1 >= 0) {
            _1_1 = 'hours';
            curHours -= 1;
        }
        if (curHours - 1 >= 0) {
            _1_2 = 'hours';
            curHours -= 1;
        }

        // Minutes:
        curMinutes = Math.round(curMinutes / 5);
        if (curMinutes - 5 >= 0) {
            if (_5 === 'hours') {
                _5 = 'both';
            } else {
                _5 = 'minutes';
            }
            curMinutes -= 5;
        }
        if (curMinutes - 3 >= 0) {
            if (_3 === 'hours') {
                _3 = 'both';
            } else {
                _3 = 'minutes';
            }
            curMinutes -= 3;
        }
        if (curMinutes - 2 >= 0) {
            if (_2 === 'hours') {
                _2 = 'both';
            } else {
                _2 = 'minutes';
            }
            curMinutes -= 2;
        }
        if (curMinutes - 1 >= 0) {
            if (_1_1 === 'hours') {
                _1_1 = 'both';
            } else {
                _1_1 = 'minutes';
            }
            curMinutes -= 1;
        }
        if (curMinutes - 1 >= 0) {
            if (_1_2 === 'hours') {
                _1_2 = 'both';
            } else {
                _1_2 = 'minutes';
            }
            curMinutes -= 1;
        }

        // Change the state:
        _5 = getColor(_5, _backColor);
        _3 = getColor(_3, _backColor);
        _2 = getColor(_2, _backColor);
        _1_1 = getColor(_1_1, _backColor);
        _1_2 = getColor(_1_2, _backColor);
        this.setState({_5, _3, _2, _1_1, _1_2});
    }

    clock() {
        this.tick();
        this.timerID = setInterval(
            () => this.tick(), 1000);
    }

    componentDidMount() {
        this.clock();
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    render() {
        return(
            <div style={styles.clock}>
                <div style={{float: `left`}}>
                    <div style={{overflow: `hidden`}}>
                        <div style={{borderWidth: `${b} 0 0 ${b}`, borderStyle: `solid`, borderColor: `black`, width: a2, height: a2, float: `left`, backgroundColor: this.state._2}}></div>
                        <div style={{float: `left`}}>
                            <div style={{borderWidth: `${b} 0 0 ${b}`, borderStyle: `solid`, borderColor: `black`, width: a, height: a, backgroundColor: this.state._1_1}}></div>
                            <div style={{borderWidth: `${b} 0 0 ${b}`, borderStyle: `solid`, borderColor: `black`, width: a, height: a, backgroundColor: this.state._1_2}}></div>
                        </div>
                    </div>
                    <div style={{borderWidth: `${b} 0 ${b} ${b}`, borderStyle: `solid`, borderColor: `black`, width: a3, height: a3, float: `left`, backgroundColor: this.state._3}}></div>
                </div>
                <div style={{borderWidth: `${b}`, borderStyle: `solid`, borderColor: `black`, width: a5, height: a5, float: `left`, backgroundColor: this.state._5}}></div>
            </div>
        );
    }
}

const FibonacciClock = () => {
    React.useEffect(() => {
        document.title = `Fibonacci Clock`;
        addBodyMinWidth550();

        return () => {
            removeBodyMinWidth550();
        }
    });

    return (
        <div id="fibonacci-clock">
            <Card style={styles.title}>
                <Card.Body>
                    <h1 className="page-title">Fibonacci Clock</h1>
                </Card.Body>
            </Card>
            <Clock />
            <Card style={styles.wellExplanation}>
                <Card.Body>
                    <Card.Text>
                        Rectangle side=1: &nbsp;&nbsp;5mins | 1 hour<br />
                        Rectangle side=2: 10mins | 2 hours<br />
                        Rectangle side=3: 15mins | 3 hours<br />
                        Rectangle side=5: 25mins | 5 hours<br />
                    </Card.Text>
                    <Card.Text>
                        So, you just sum up the sides of the rectangles which are lighted up (Red for hours, Green for Minutes and Blue for both).
                    </Card.Text>
                    <Card.Text>
                        For example, 9:55 would be 5+3+1 hours and (5+3+2+1)*5 mins.
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}

export default FibonacciClock;
