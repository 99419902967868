import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Components:
import AchNavbar from './components/AchNavbar/AchNavbar.jsx';
import Signature from './components/Signature/Signature.jsx';
import PortfolioContainer from './components/PortfolioContainer/PortfolioContainer.jsx';
import GameOfLife from './components/GameOfLife/GameOfLife.jsx';
import RecipeBox from './components/RecipeBox/RecipeBox.jsx';
import MarkdownPreviewer from './components/MarkdownPreviewer/MarkdownPreviewer.jsx';
import FibonacciClock from './components/FibonacciClock/FibonacciClock.jsx';
import SimonGame from './components/SimonGame/SimonGame.jsx';
import TicTacToe from './components/TicTacToe/TicTacToe.jsx';
import PomodoroClock from './components/PomodoroClock/PomodoroClock.jsx';
import Calculator from './components/Calculator/Calculator.jsx';
import TwitchViewer from './components/TwitchViewer/TwitchViewer.jsx';
import WikipediaViewer from './components/WikipediaViewer/WikipediaViewer.jsx';
import LocalWeather from './components/LocalWeather/LocalWeather.jsx';
import RandomQuotes from './components/RandomQuotes/RandomQuotes.jsx';
import Error404 from '../Helper/Error404.jsx';

// CSS
import './Portfolio.sass';

const Outline = () => (
    <>
        <AchNavbar/>
        {/* Το Switch χρειάζεται μόνο για τη γραμμή χωρίς το path. Χωρίς το switch κάνει render το Error404 συνέχεια */}
        <div style={{paddingTop: '50px', paddingBottom: '50px'}}>
            <Switch>
                <Route exact path="/" component={PortfolioContainer} />
                <Route path="/portfolio/game-of-life" component={GameOfLife} />
                <Route path="/portfolio/recipe-box" component={RecipeBox} />
                <Route path="/portfolio/markdown-previewer" component={MarkdownPreviewer} />
                <Route path="/portfolio/fibonacci-clock" component={FibonacciClock} />
                <Route path="/portfolio/simon-game" component={SimonGame} />
                <Route path="/portfolio/tic-tac-toe" component={TicTacToe} />
                <Route path="/portfolio/pomodoro-clock" component={PomodoroClock} />
                <Route path="/portfolio/calculator" component={Calculator} />
                <Route path="/portfolio/twitch-viewer" component={TwitchViewer} />
                <Route path="/portfolio/wikipedia-viewer" component={WikipediaViewer} />
                <Route path="/portfolio/local-weather" component={LocalWeather} />
                <Route path="/portfolio/random-quotes" component={RandomQuotes} />
                <Route component={Error404} />
            </Switch>
        </div>
        <Signature/>
    </>
);

ReactDOM.render(<BrowserRouter><Outline /></BrowserRouter>, document.getElementById("react-root"));
