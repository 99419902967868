import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import "./WikipediaViewer.sass";
import { addBodyMinWidth400, removeBodyMinWidth400 } from '../../../Helper/Utils';
import { useState } from 'react';
import { useEffect } from 'react';

const MainComponent = () => {
    const [searchQuery, setSearchQuery] = React.useState('');
    const [results, setResults] = React.useState([]);

    const handleChange = (e) => setSearchQuery(e.target.value);

    const submit = (e) =>  {
        e.preventDefault();

        if (!searchQuery) {
            setResults([]);
            return;
        }

        axios.get(`/api/wikipedia-viewer/${searchQuery}`)
            .then((response) => {
                setResults(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
    }

    let i = 0;
    const resultList = results.filter(ele => Boolean(ele)).map((ele) => {
        const pageNum = Object.keys(ele.query.pages)[0];
        return (
            <div key={i++}>
                <p>
                    <strong>
                        <a href={`http://en.wikipedia.org/?curid="${ele.query.pages[pageNum].pageid}"`} target="_blank" rel="noopener">{ele.query.pages[pageNum].title}</a>
                    </strong>
                </p>
                <p>{ele.query.pages[pageNum].extract}</p>
                <hr />
            </div>
        )
    });
    const resultsDiv = (
        <Container>
            <Row>
                <Col xs={12} sm={{ span: 10, offset: 1 }}>
                    <Card style={{ marginBottom: '20px' }}>
                        <Card.Body>
                            {resultList}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

    return (
        <div>
            <Container>
                <Row style={results.length === 0 ? {paddingTop: '20%'} : {}}>
                    <Col xs={12} sm={{ span: 10, offset: 1 }}>
                        <Card style={{ marginBottom: '20px' }}>
                            <Card.Body>
                                <Form>
                                    <Form.Group as={Row}>
                                        <Col xs={12} md={7}>
                                            <Form.Control type="text" value={searchQuery} placeholder="Search Wikipedia" onChange={(e) => handleChange(e)} />
                                        </Col>
                                        <Col xs={12} md={5} className="wikipedia-buttons">
                                            <Button variant="dark" type="submit" onClick={(e) => submit(e)} ><i className="fa fa-search"></i></Button>
                                            {' '}
                                            <Button variant="dark" href="https://en.wikipedia.org/wiki/Special:Random" target="_blank" rel="noopener">Random Page!</Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {results.length > 0 && resultsDiv}
        </div>
    );
}

const WikipediaViewer = () => {
    useEffect(() => {
        document.title = 'Markdown Previewer';
        addBodyMinWidth400();

        return () => {
            removeBodyMinWidth400();
        };
    });

    return (
        <div id="wikipedia-viewer">
            <h1 className="page-title">Wikipedia Viewer</h1>
            <MainComponent />
        </div>
    );
}

export default WikipediaViewer;
