import React from 'react';
import { evaluate } from 'mathjs';
import { addBodyMinWidth400, removeBodyMinWidth400 } from '../../../Helper/Utils';
import "./Calculator.sass";

const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
const operands = ['+', '-', '*', '/'];
const symbols = [...operands, '.'];

class MainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {screen: '0'};
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress);
    }

    handleKeyPress(e) {
        // console.log(e.key);
        if (numbers.includes(e.key) || symbols.includes(e.key)) {
            this.handleClick(e.key.toString());
        } else if (e.key === ",") {
            this.handleClick(".");
        } else if (e.key === "Backspace") {
            this.handleClick("Backspace");
        } else if (e.key === "Delete") {
            this.handleClick("Delete");
        } else if (e.key === "Enter") {
            this.handleClick("Enter");
        }
    }

    handleClick(n) {
        // console.log(n);
        if (typeof n !== 'string')  {
            console.log(`Debug: Error in function 'handleClick': input must be string`);
            return;
        }

        let screen = this.state.screen;

        if (n === "Backspace") {
            if (screen.length > 1) {
                screen = screen.slice(0, -1);
                this.setState({screen});
            } else if (screen.length === 1) {
                this.clear();
            }
            return;
        }

        if (n === "Delete") {
            this.clear();
            return;
        }

        if (n === "Enter") {
            // If the last character is a number:
            if (screen.match(/\d$/)) {
                screen = evaluate(screen);
                screen = screen.toString();
                this.setState({screen});
                return;
            }
        }

        // Is there 'Inifnity' on screen?
        if (screen === "Infinity") {
            if (numbers.includes(n)) {
                screen = n;
            } else if (n === "Delete") {
                this.clear();
                return;
            }
            this.setState({screen});
            return;
        }

        // Is there only '0' on screen?
        if (screen === "0") {
            // If the button is a number except zero
            if (numbers.includes(n)) {
                screen = n;
            } else {
                screen += n;
            }
        } else if (screen.length <= 17 * 2) {
            // If the last character is a number:
            if (screen.match(/\d$/)) {
                // If the last number has a comma
                if (screen.match(/\d+\.\d*[+\-*/]?$/)) {
                    if (numbers.includes(n) || operands.includes(n)) {
                        screen += n;
                    }
                } else if (numbers.includes(n) || symbols.includes(n)) {
                    screen += n;
                }
            } else if (screen.match(/[+\-*/\.]$/)) {
                // If the last character is an operator or a comma:
                // If the last number has a comma
                if (screen.match(/\d+\.\d*[+\-*/]?$/)) {
                    if (numbers.includes(n)) {
                        screen += n;
                    } else if (operands.includes(n)) {
                        screen = screen.slice(0, -1) + n;
                    }
                } else if (numbers.includes(n)) {
                    screen += n;
                } else if (symbols.includes(n)) {
                    screen = screen.slice(0, -1) + n;
                }
            }
        }
        this.setState({screen});
    }

    clear() {
        this.setState({screen: '0'});
    }

    render() {
        return (
            <div className="panel">
                <h1 className="title">Calculator</h1>
                <div className="screen">{this.state.screen}</div>

                {/* Keypad */}
                <div className="keypad">
                    <button onClick={() => this.handleClick('7')}>7</button>
                    <button onClick={() => this.handleClick('8')}>8</button>
                    <button onClick={() => this.handleClick('9')}>9</button>
                    <button onClick={() => this.handleClick('/')}><span className="division-symbol">/</span></button>
                    <br />
                    <button onClick={() => this.handleClick('4')}>4</button>
                    <button onClick={() => this.handleClick('5')}>5</button>
                    <button onClick={() => this.handleClick('6')}>6</button>
                    <button onClick={() => this.handleClick('*')}><i className="fa fa-times" aria-hidden="true"></i></button>
                    <br />
                    <button onClick={() => this.handleClick('1')}>1</button>
                    <button onClick={() => this.handleClick('2')}>2</button>
                    <button onClick={() => this.handleClick('3')}>3</button>
                    <button onClick={() => this.handleClick('-')}><i className="fa fa-minus" aria-hidden="true"></i></button>
                    <button onClick={() => this.handleClick('Backspace')}><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                    <button onClick={() => this.handleClick('.')}>.</button>
                    <button onClick={() => this.handleClick('0')}>0</button>
                    <button onClick={() => this.handleClick('Enter')}>=</button>
                    <button onClick={() => this.handleClick('+')}><i className="fa fa-plus" aria-hidden="true"></i></button>
                    <button onClick={() => this.handleClick('Delete')}>C</button>
                </div>
            </div>
        );
    }
}

const Calculator = () => {
    React.useEffect(() => {
        document.title = `Calculator`;
        addBodyMinWidth400();

        return () => {
            removeBodyMinWidth400();
        };
    });

    return (
        <div id="calculator">
            <MainComponent />
        </div>
    );
}

export default Calculator;
