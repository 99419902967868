import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { addBodyMinWidth550, removeBodyMinWidth550 } from '../../../Helper/Utils';
import "./GameOfLife.sass";

const x_max = 50;
const y_max = 50;
const VERBOSE = false;
let timeInterval = 100;

const checkNeighbors = (arr, i, j, color1, i_max = x_max, j_max = y_max) => {
    // arr: array name
    // color1: for example 'full' or true
    let neighborhood = 0;
    for (let m = -1; m < 2; m++) {
        for (let n = -1; n < 2; n++) {
            // Excluding itself:
            if (m !== 0 || n !== 0) {
                // Catch out of boundaries:
                if (i + m === -1 || j + n === -1 || i + m === i_max || j + n === j_max) {
                    continue;
                } else {
                    if (arr[i + m][j + n] === color1) {
                        neighborhood++;
                    }
                }
            }
        }
    }
    return neighborhood;
}

class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _matrix: Array(x_max).fill(false).map(() => Array(y_max).fill(false)),
            running: false,
            globalTime: 0
        }

        this.toggle = this.toggle.bind(this);
    }

    tick() {
        let count = 0;
        const t1 = new Date().getTime();
        let newArr = this.state._matrix.map(arr => arr.slice(0));
        // let newArr = JSON.parse(JSON.stringify(this.state._matrix));
        for (let i = 0; i < x_max; i++) {
            for (let j = 0; j < y_max; j++) {
                const neighbors = checkNeighbors(this.state._matrix, i, j, true);
                if (neighbors === 0) {
                    // Death (isolation):
                    newArr[i][j] = false;
                    count++; // Counter in order to determine if the board is empty.
                    // count++; // Counter in order to determine if the board is empty.
                } else if (neighbors === 1) {
                    // Death (isolation):
                    newArr[i][j] = false;
                    // Check if the board is completely empty:
                } else if (neighbors === 3) {
                    // Birth:
                    newArr[i][j] = true;
                } else if (neighbors >= 4) {
                    // Death (overcrowding):
                    newArr[i][j] = false;
                }
            }
        }

        // Update the actual board:
        this.setState({_matrix: newArr});

        // Check if the board is completely empty:
        if (count === x_max * y_max) {
            if (VERBOSE) console.log('Empty board!');
            this.stopIteration();
            this.props.changeGenerations('reset');
            this.setState({globalTime: 0});
        }

        var t2 = new Date().getTime();

        if (this.props.generations > 2) {
            this.setState(prevState => ({
                globalTime: prevState.globalTime + (t2 - t1)
            }), () => {
                const mesosOros = this.state.globalTime/this.props.generations;
                if (VERBOSE) console.log('Generation: %d | Generation average: ' + Math.round(mesosOros) + ' ms', this.props.generations); // measure time step 5
            });
        }
        this.props.changeGenerations('plus');
        if (VERBOSE) console.log(`Iteration time: ${t2 - t1} ms`);
    }

    randomize() {
        // START - Pick a random 10% - 15% of the cells:
        let newArr = this.state._matrix.map( arr => arr.slice(0) );
        for (let i in newArr) {
            for (let j in newArr[i]) {
                if (Math.floor(Math.random() * 6) === 1) {
                    newArr[i][j] = true;
                }
            }
        }
        this.setState({_matrix: newArr});
    }

    toggle(i, j) {
        if (VERBOSE) console.log('i:', i, 'j:', j);
        const t1 = new Date().getTime(); // Time 1

        let newArray = this.state._matrix.map(arr => arr.slice(0));
        // newArray[i][j] = !newArray[i][j];
        if (newArray[i][j] === true) {
            newArray[i][j] = false;
        } else {
            newArray[i][j] = true;
        }
        this.setState({_matrix: newArray});

        if (VERBOSE) console.log('Neighbors:', checkNeighbors(this.state._matrix, i, j, true));

        const t2 = new Date().getTime(); // Time 2
        if (VERBOSE) console.log(`Time: ${t2 - t1} ms`);
    }

    componentDidMount() {
        this.randomize();
        this.startIteration();
    }

    componentWillUnmount() {
        clearInterval(this.timer1);
    }

    startIteration() {
        this.setState({
            running: true
        }, () => this.props.transferState(this.state.running));
        this.timer1 = setInterval(() => this.tick(), timeInterval);
    }

    stopIteration() {
        this.setState({
            running: false
        }, () => this.props.transferState(this.state.running));
        clearInterval(this.timer1);
    }

    pause() {
        (this.state.running) ? this.stopIteration() : this.startIteration();
    }

    clear() {
        let newArr = this.state._matrix.map(arr => arr.slice(0));
        for (let i in newArr) {
            for (let j in newArr[i]){
                newArr[i][j] = false;
            }
        }
        this.setState({_matrix: newArr});
        this.props.changeGenerations('reset');
    }

    // Creating the rows for the board.
    createTable() {
        let rows = [];
        for (let i = 0; i < x_max; i++) {
            // START - Creating the columns for each row.
            let columns = [];
            for (let j = 0; j < y_max; j++) {
                columns.push(<td key={j} onClick={this.toggle.bind(this, i, j)} style={{backgroundColor: this.state._matrix[i][j] ? 'red' : 'transparent'}}></td>);
            }
            // END - Creating the columns for each row.
            rows.push(<tr key={i}>{columns}</tr>);
        }
        return rows;
    }

    render() {
        return (
            <table className="gol-table">
                <tbody>{this.createTable()}</tbody>
            </table>
        );
    }
}

export default class GameOfLife extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            runningOutline: false,
            generations: 0
        };
        this.transferState = this.transferState.bind(this);
        this.changeGenerations = this.changeGenerations.bind(this);
    }

    transferState(input) {
        this.setState({
            runningOutline: input
        });
    }

    changeGenerations(input) {
        if (input === 'plus') {
            this.setState(prevState => ({
                generations: prevState.generations + 1
            }));
        } else if (input === 'reset') {
            this.setState({generations: 0});
        } else {
            console.error(`'changeGenerations' error input!`);
        }

    }

    componentDidMount() {
        document.title = `Game of Life`;
        addBodyMinWidth550();
    }

    componentWillUnmount() {
        removeBodyMinWidth550();
    }

    render() {
        return (
            <div id="game-of-life">
                <h1 className="page-title">Game of Life</h1>
                <div className='text'>You can add or remove cells while running.</div>
                {/* <MainComponent(s) /> */}
                <TableComponent ref={instance => { this.child = instance; }} transferState={this.transferState} generations={this.state.generations}
                changeGenerations = {this.changeGenerations} />
                <div className='button-group'>
                    <ButtonGroup>
                        <Button variant="light" onClick={() => this.child.pause()}>{this.state.runningOutline ? 'Pause' : 'Play'}</Button>
                        <Button variant="light" onClick={() => this.child.clear()}>Clear</Button>
                        <Button variant="light" onClick={() => this.child.randomize()}>Randomize</Button>
                    </ButtonGroup>
                </div>
                <div className='text'>Generations: {this.state.generations}</div>
            </div>
        );
    }
}
