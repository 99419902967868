import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { addBodyMinWidth400, removeBodyMinWidth400 } from '../../../Helper/Utils';

const styles = {
    quote: {
        textAlign: 'center'
    },
    author: {
        textAlign: 'right'
    },
    source: {
        textAlign: 'right'
    },
    nextQuote: {
        color: '#E1E1E1',
        backgroundColor: '#000',
        lineHeight: '29px'
    },
    twitter: {
        color: '#E1E1E1',
        backgroundColor: '#000',
        float: 'right'
    }
};

const getRequest = (method, url, cache = true, callback) => {
    const xhttp = new XMLHttpRequest();
    let newUrl = url;

    xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
			callback(JSON.parse(xhttp.responseText)); // if this line throws an error then just replace it with the line below and take care of the JSON.parse(string) in the call
        }
    };
    if (!cache) {
        newUrl = newUrl.concat(`?_=${new Date().getTime()}`)
    };
    xhttp.open(method, newUrl, true);
    xhttp.send();
};

const createTwitterPost = (quote) => {
    const quoteContent = quote.content;
    const quoteAuthor = quote.title;
    let twitterContent = quoteContent
    .replace(/\.\s\s/gi, ".")
    .replace(/<\/?[a-z]+>/gi, "")
    .replace(/<br ?\/?>/gi, "%0A")
    .replace(/&#8211;/g, "%E2%80%93")
    .replace(/&#8217;/g, "%E2%80%99")
    .replace(/;/g, "%3B")
    .replace(/&#8230;/g, "%E2%80%A6");

    const tweetLength = (`"${twitterContent}" ${quoteAuthor} #quotes `).length;
    if (tweetLength > 280) {
        twitterContent = twitterContent.slice(0, 280 - (tweetLength + 3));
        twitterContent += `...`;
    }
    window.open(`//twitter.com/intent/tweet?hashtags=quotes&related=freecodecamp&text="${twitterContent}" ${quoteAuthor}`);
}

const MainComponent = () => {
    const [quote, setQuote] = React.useState(null); // Object

    const getQuote = () => {
        getRequest("GET", `/api/random-quotes/random`, false, res => setQuote(res));
    }

    const displaySource = () => {
        if (typeof quote.custom_meta !== 'undefined' && typeof quote.custom_meta.Source !== 'undefined') {
            return(
                <p style={styles.source}>Source: <span dangerouslySetInnerHTML={{__html: quote.custom_meta.Source}}></span></p>
            );
        }
    }

    const randomQuote = () => (
        <div>
            {/* quote content */}
            <span style={styles.quote} dangerouslySetInnerHTML={{__html: quote.content.replace("<p>", "<p><i class='fa fa-quote-left'></i> ")}}></span>

            {/* quote author */}
            <p style={styles.author}>― {quote.title}</p>

            {/* quote source, if available */}
            {displaySource()}

            <Button variant="dark" style={styles.nextQuote} onClick={() => getQuote()}>Next Quote</Button>
            <Button variant="dark" style={styles.twitter} onClick={() => createTwitterPost(quote)}><i className="fa fa-twitter fa-2x"></i></Button>
        </div>
    )

    React.useEffect(() => {
        !quote && getQuote();
    });

    return (
        <Container>
            <Row>
                <Col xs={12} sm={{ span: 10, offset: 1}}>
                    <Card>
                        <Card.Body>
                            {quote ? randomQuote() : "Loading..."}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const RandomQuotes = () => {
    React.useEffect(() => {
        document.title = 'Random Quotes';
        addBodyMinWidth400();

        return () => {
            removeBodyMinWidth400();
        };
    });

    return (
        <>
            <h1 className="page-title">Random Quotes</h1>
            <MainComponent />
        </>
    );
}

export default RandomQuotes;
