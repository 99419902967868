import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { addBodyMinWidth400, removeBodyMinWidth400 } from '../../../Helper/Utils';

const getRequest = (method, url, callback) => {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            callback(JSON.parse(this.responseText));
        }
    };
    xhttp.open(method, url, true);
    xhttp.send(); //.jsx
}

const capitalizeFirstLetter = (string) => {
    let arr = string.split(' ');
    for (let i in arr) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(' ');
}

class WeatherPanel extends React.Component {
    constructor(props) {
        super(props);
        this._geolocation = false;
        this.state = {
            name: null,
            country: null,
            img: null,
            description: null,
            humidity: null,
            wind: null,
            date1: null,
            lastUpdate: null,
            sunrise1: null,
            sunset1: null
        };
    }

    componentDidMount() {
        var connectToApi = in_coords => {
            this._geolocation = true;

            getRequest("GET", `/api/local-weather/${in_coords.Latitude}/${in_coords.Longitude}`, j => {
                const date = new Date(j.dt * 1000);
                const month = date.getMonth() + 1;
                const hour = date.getHours();
                const _date = `${date.getDate()}/${month}/${date.getFullYear()}`;
                const _time = `${("0" + hour).substr(-2)}:${("0" + date.getMinutes()).substr(-2)}:${("0" + date.getSeconds()).substr(-2)}`;
                this.setState({
                    name: j.name,
                    country: j.sys.country,
                    img: 'https://openweathermap.org/img/w/' + j.weather[0].icon + '.png',
                    description: j.weather[0].description,
                    degrees: Math.round((j.main.temp - 272.15) * 10) / 10,
                    humidity: j.main.humidity,
                    wind: Math.round((j.wind.speed * 3.6) * 10) / 10,
                    lastUpdate: `${_date} @ ${_time}`,
                    sunrise1: j.sys.sunrise,
                    sunset1: j.sys.sunset,
                    date1: j.dt
                });
            });
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                // const curLatitude = position.coords.latitude;
                // const curLongtitude = position.coords.longitude;
                var crd = position.coords;
                console.log('Your current position is:');
                console.log(`Latitude : ${crd.latitude}`);
                console.log(`Longitude: ${crd.longitude}`);
                console.log(`More or less ${crd.accuracy} meters.`);
                const foo = {'Latitude': crd.latitude, 'Longitude': crd.longitude, 'Accuracy': crd.accuracy};
                localStorage.setItem('coords', JSON.stringify( foo ));
                connectToApi(foo);
            }, err => {
                console.warn(`ERROR(${err.code}): ${err.message}`);
            }, { timeout: 5000 });
        } else {
            console.log('No navigator.geolocation');
        }

        // Get the coords from the local storage:
        const localCoords = JSON.parse(localStorage.getItem('coords'));
        if (localCoords) {
            connectToApi(localCoords);
        }
    }

    changeColors(in_sunrise, in_sunset, date) {
        var dateSunrise = new Date(in_sunrise);
        // console.log('in_sunrise:', in_sunrise);
        var minutesSunrise = dateSunrise.getMinutes() + dateSunrise.getHours() * 60;
        var dateSunset = new Date(in_sunset);
        var minutesSunset = dateSunset.getMinutes() + dateSunset.getHours() * 60;
        var minutesHour = date.getHours() * 60 + date.getMinutes();
        if (minutesHour > minutesSunset + 45 && minutesHour < minutesSunrise - 45) {
            document.body.style.backgroundColor = 'black';
        } else {
            // code to be executed when during the day.
            // Below it's a cosine function starting from sunrise up to sunset.
            // f(x) = -{cos[2Pi/(d2-d1)*(x-d1)] + 1}*y_max/2;
            // For full spectrum change 15 (grayscale) -> 16777215
            var colorsDec = (-Math.cos(2 * 3.14 / (minutesSunset - minutesSunrise) * (minutesHour - minutesSunrise)) + 1) * 15 / 2;
            colorsDec = Math.floor(colorsDec);
            //console.log(colorsDec);
            var colorsHex = colorsDec.toString(16);
            colorsHex = '#' + colorsHex + colorsHex + colorsHex; // This line is for grayscale. Comment this line for full spectrum.
            // console.log(colorsHex);
            //console.log((minutesHour - minutesSunrise)/(minutesSunset - minutesSunrise)); //Percentage!!
            document.body.style.backgroundColor = colorsHex;
        }
    }

    render() {
        this.changeColors(this.state.sunrise1 * 1000, this.state.sunset1 * 1000, new Date(this.state.date1 * 1000));
        if (!this._geolocation) {
            return (
                <Container style={{maxWidth: '500px'}}>
                    <Card>
                        <Card.Body>
                            Please allow geolocation...
                        </Card.Body>
                    </Card>
                </Container>
            );
        }
        const localCoords = JSON.parse(localStorage.getItem('coords'));
        let distance = Math.round(localCoords.Accuracy / 1000);
        distance = distance > 1 ? `(± ${distance} km)` : ``;

        return (
            <Container style={{maxWidth: '500px'}}>
                <Card>
                    <Card.Body>
                        {this.state.name}, {this.state.country} {distance}
                        <table style={{width: '100%'}}>
                            <tbody>
                                <tr>
                                    <td><img src={this.state.img} title={this.state.description}></img></td>
                                    <td>{this.state.degrees} °C<br />
                                        {capitalizeFirstLetter(this.state.description)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Humidity:</td>
                                    <td>{this.state.humidity} %</td>
                                </tr>
                                <tr>
                                    <td>Wind:</td>
                                    <td>{this.state.wind} km/h</td>
                                </tr>
                                <tr>
                                    <td>Last Update:</td>
                                    <td>{this.state.lastUpdate}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

const LocalWeather = () => {
    React.useEffect(() => {
        document.title = 'Local Weather';
        addBodyMinWidth400();

        return () => {
            removeBodyMinWidth400();
        };
    });

    return (
        <div>
            <h1 className="page-title">Local Weather</h1>
            <h4 style={{ color: "white",  textAlign: "center" }}>The background changes color depending on the hour of the day.</h4>
            <WeatherPanel />
        </div>
    );
};

export default LocalWeather;
