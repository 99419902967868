import React from 'react';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import "./TwitchViewer.sass";
import { addBodyMinWidth400, removeBodyMinWidth400 } from '../../../Helper/Utils';

class MainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            streamers: []
        };
    }

    componentDidMount() {
        axios.get(`/api/twitch-viewer`)
            .then((response) => {
                console.log(response.data);
                this.setState({
                    streamers: response.data
                });
            }).catch((error) => {
                console.log(error);
            });
    }

    render() {
        const rows = this.state.streamers.length > 0 && this.state.streamers.map((streamer) => {
            const streamerName = streamer.name;
            let backgroundColor;
            let image = {};
            if (streamer.response.stream === null) {
                // Offline //
                backgroundColor = '#669';
                image = {
                    src: '/images/Portfolio/twitchViewer/circleGray.png',
                    alt: 'Offline'
                };
                // message: ''

            } else if (streamer.response.error) { //an yparxei error sto input
                // Error //
                backgroundColor = '#999';
                image = {
                    src: '/images/Portfolio/twitchViewer/circleRed.png',
                    alt: 'Error'
                };
                // message: <i>{ele.message}</i>

            } else {
                // Online //
                backgroundColor = '#099';
                image = {
                    src: '/images/Portfolio/twitchViewer/circleGreen.png',
                    alt: 'Online'
                };
                // message: ele.stream.channel.status
            }
            return <Stream backgroundColor={backgroundColor} image={image} streamerName={streamerName} key={streamerName} />;
        });
        if (this.state.streamers.length > 0) {
            return (
                <Table>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
            );
        } else {
            return (
                <div className='loading'>Loading...</div>
            );
        }
    }
}

const Stream = ({backgroundColor, image, streamerName}) => (
    <tr style={{backgroundColor}}>
        <td><Image src={image.src} alt={image.alt} /></td>
        <td><a href={`//twitch.tv/${streamerName}`} target="_blank" style={{color: 'inherit'}} rel="noopener">{streamerName}</a></td>
        {/* <td>{row.message}</td> */}
    </tr>
)

const TwitchViewer = () => {
    React.useEffect(() => {
        document.title = `Twitch Viewer`;
        addBodyMinWidth400();

        return () => {
            removeBodyMinWidth400();
        };
    });

    return (
        <div id="twitch-viewer">
            <h1 className="page-title">Twitch Viewer</h1>
            <MainComponent />
        </div>
    );
}

export default TwitchViewer;
