import React from 'react';
import './Signature.sass';

const Signature = () => (
    <div id="signature">
        <div className="parent">
            <div className="child fancy-underline">
                <span>Coded by <a href="https://www.achipapakon.com" target="_blank" rel="noopener"><strong>Achilleas Papakonstantinou</strong></a> | </span><span className="copyright">Copyright © 2016-{new Date().getFullYear()}</span>
            </div>
        </div>
    </div>
);

export default Signature;
