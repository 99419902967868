import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addBodyMinWidth550, removeBodyMinWidth550 } from '../../../Helper/Utils';
import "./TicTacToe.sass";

// returns the available spots on the board
const emptyIndexies = (board) => board.filter(s => s != "O" && s != "X");

// winning combinations using the board indexies for instace the first win could be 3 xes in a row
const winning = (board, player) => (
    (board[0] == player && board[1] == player && board[2] == player) ||
    (board[3] == player && board[4] == player && board[5] == player) ||
    (board[6] == player && board[7] == player && board[8] == player) ||
    (board[0] == player && board[3] == player && board[6] == player) ||
    (board[1] == player && board[4] == player && board[7] == player) ||
    (board[2] == player && board[5] == player && board[8] == player) ||
    (board[0] == player && board[4] == player && board[8] == player) ||
    (board[2] == player && board[4] == player && board[6] == player)
);
class MainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            values: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            symbolPlayer: null,
            turn: 0
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(n) { // string n
        this.setState({
            show: false,
            symbolPlayer: n,
            turn: 1
        });
    }

    handleShow() {
        this.setState({ show: true });
    }

    componentDidMount() {
        this.setState({ show: true });
    }

    divClick(n) {
        if (this.state.turn < 1) return;

        let values = this.state.values.slice(0);
        // human
        let huPlayer = this.state.symbolPlayer;  // e.g. 'X'
        // ai
        let aiPlayer;
        if (this.state.symbolPlayer === 'X') {
            aiPlayer = 'O';
        } else if (this.state.symbolPlayer === 'O') {
            aiPlayer = 'X';
        }
        if (typeof values[n] === 'number') values[n] = huPlayer;
        else return;

        let isTie;

        if (emptyIndexies(values).length === 0) {
            isTie = true;
        } else {

            // CPU's turn: (don't forget to set timeout half a second!)

            // var origBoard = [0, 1 , "X", 3, "O" , "O", 6 , 7, 8];

            // this is the board flattened and filled with some values to easier asses the Artificial Inteligence.
            const origBoard = values;
            // console.log(`origBoard: ${origBoard}`);

            // keep track of function calls
            var fc = 0;

            // finding the ultimate play on the game that favors the computer
            var bestSpot = minimax(origBoard, aiPlayer);
            values[bestSpot.index] = aiPlayer;
            // console.log(values);

            //loging the results
            // console.log("index: " + bestSpot.index);
            // console.log("function calls: " + fc);

            // the main minimax function
            function minimax(newBoard, player) {

                //keep track of function calls;
                fc++;

                //available spots
                var availSpots = emptyIndexies(newBoard);

                // checks for the terminal states such as win, lose, and tie and returning a value accordingly
                if (winning(newBoard, huPlayer)) {
                    return { score: -10 };
                }
                else if (winning(newBoard, aiPlayer)) {
                    return { score: 10 };
                }
                else if (availSpots.length === 0) {
                    return { score: 0 };
                }

                // an array to collect all the objects
                var moves = [];

                // loop through available spots
                for (var i = 0; i < availSpots.length; i++) {
                    //create an object for each and store the index of that spot that was stored as a number in the object's index key
                    var move = {};
                    move.index = newBoard[availSpots[i]];

                    // set the empty spot to the current player
                    newBoard[availSpots[i]] = player;

                    //if collect the score resulted from calling minimax on the opponent of the current player
                    if (player == aiPlayer) {
                        var result = minimax(newBoard, huPlayer);
                        move.score = result.score;
                    }
                    else {
                        var result = minimax(newBoard, aiPlayer);
                        move.score = result.score;
                    }

                    //reset the spot to empty
                    newBoard[availSpots[i]] = move.index;

                    // push the object to the array
                    moves.push(move);
                }

                // if it is the computer's turn loop over the moves and choose the move with the highest score
                var bestMove;
                if (player === aiPlayer) {
                    var bestScore = -10000;
                    for (var i = 0; i < moves.length; i++) {
                        if (moves[i].score > bestScore) {
                            bestScore = moves[i].score;
                            bestMove = i;
                        }
                    }
                } else {

                    // else loop over the moves and choose the move with the lowest score
                    var bestScore = 10000;
                    for (var i = 0; i < moves.length; i++) {
                        if (moves[i].score < bestScore) {
                            bestScore = moves[i].score;
                            bestMove = i;
                        }
                    }
                }

                // return the chosen move (object) from the array to the higher depth
                return moves[bestMove];
            }

        }

        this.setState({ values }, () => foo());

        const foo = () => {
            setTimeout(() => {
                if (winning(values, aiPlayer)) {
                    alert('You have lost!');
                    this.setState({ turn: -1 });
                } else if (winning(values, huPlayer)) {
                    alert('You win!');
                    this.setState({ turn: -1 });
                } else if (emptyIndexies(values).length === 0 || isTie) {
                    alert('Tie!');
                    this.setState({ turn: -1 });
                }
            }, 0);
        }

        this.setState(prevState => ({ turn: prevState.turn + 1 }) );
    }

    showValue(n) {
        if (this.state.values[n] === 'X') return <i className="fa fa-times fa-4x"></i>;
        else if (this.state.values[n] === 'O') return <i className="fa fa-circle-thin fa-4x"></i>;
    }

    playAgainClick() {
        this.setState({
            show: true,
            values: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            symbolPlayer: null,
            turn: 0
        });
    }

    render() {
        const playAgain = () => {
            if (this.state.turn === -1) {
                return (
                    <div style={{textAlign: 'center', marginTop: '5px'}}>
                        <Button variant="light" onClick={() => this.playAgainClick()}>Play Again!</Button>
                    </div>
                );
            }
        };
        return (
            <div>
                <Modal show={this.state.show} onHide={this.handleClose} size="small" backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Choose colors</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>'X' or 'O'?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="dark" onClick={() => this.handleClose('X')}>X</Button>
                        <Button variant="dark" onClick={() => this.handleClose('O')}>O</Button>
                    </Modal.Footer>
                </Modal>
                <div className="board-parent">
                    <div className="board-child">
                        <div className="board-row">
                            <div className="piece" onClick={() => this.divClick(0)}>{this.showValue(0)}</div>
                            <div className="piece" onClick={() => this.divClick(1)}>{this.showValue(1)}</div>
                            <div className="piece" onClick={() => this.divClick(2)}>{this.showValue(2)}</div>
                        </div>
                        <div className="board-row">
                            <div className="piece" onClick={() => this.divClick(3)}>{this.showValue(3)}</div>
                            <div className="piece" onClick={() => this.divClick(4)}>{this.showValue(4)}</div>
                            <div className="piece" onClick={() => this.divClick(5)}>{this.showValue(5)}</div>
                        </div>
                        <div className="board-row">
                            <div className="piece" onClick={() => this.divClick(6)}>{this.showValue(6)}</div>
                            <div className="piece" onClick={() => this.divClick(7)}>{this.showValue(7)}</div>
                            <div className="piece" onClick={() => this.divClick(8)}>{this.showValue(8)}</div>
                        </div>
                    </div>
                </div>
                {playAgain()}
            </div>
        );
    }
}

const TicTacToe = () => {
    React.useEffect(() => {
        document.title = `Tic Tac Toe`;
        addBodyMinWidth550();

        return () => {
            removeBodyMinWidth550();
        };
    });

    return (
        <div id="tic-tac-toe">
            <h1 className="page-title">Tic Tac Toe</h1>
            <MainComponent />
        </div>
    );
}

export default TicTacToe;
