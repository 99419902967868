import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ToggleButton from 'react-toggle-button';
import '/src/Helper/DigitalNumbers.sass';
import './SimonGame.sass';
import { addBodyMinWidth550, removeBodyMinWidth550 } from '../../../Helper/Utils';

const css = {
    strictButton: {
        color: `red`,
        boxShadow: `1px 1px #555`,
        transform: `translate(3px, 3px)`
    },
    crystal1Playing: {
        boxShadow: `0 0 15px blue`
    },
    crystal2Playing: {
        boxShadow: `0 0 15px red`
    },
    crystal3Playing: {
        boxShadow: `0 0 15px green`
    },
    crystal4Playing: {
        boxShadow: `0 0 15px #ffff80`
    },
};

// Phase A: playing the sounds
// Phase B: player clicking the crystals
// Phase C: pseudo-phase after phase B

class MainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leftNumber: 0,
            rightNumber: 0,
            strict: false,
            isOn: false,
            seriesCPU: [],
            seriesPlayer: [],
            crystalPlaying: null,
            delay: false,  // indication that a sound is playing
            phase: 'none' // 'A', 'B', 'C', 'none'
        };
    }

    btnStartClick() {
        clearTimeout(this.timeout);
        this.setState({
            seriesCPU: []
        }, () => {
            this.game();
        });
    }

    game(in_fail) { // bool in_fail
        // Phase A:
        this.setState({ phase: 'A' });
        const randomCrystal = Math.floor(Math.random() * 4) + 1;  // output: 1, 2, 3, 4
        let seriesCloneCPU = this.state.seriesCPU.slice(0);
        if (in_fail && this.state.strict) {
            seriesCloneCPU = [];
            seriesCloneCPU.push(randomCrystal);
        }
        // console.log(seriesCloneCPU);
        if (!in_fail) seriesCloneCPU.push(randomCrystal);
        let i = 0;
        const playSeries = () => {
            this.playSoundsTimeout = setTimeout(() => {
                let playPromise = this.state[`crystal${seriesCloneCPU[i]}`].play();
                if (playPromise !== undefined) {
                    playPromise.catch(error => {});
                }
                // Do the corresponding animation:
                this.animate(seriesCloneCPU[i]);
                i++;
                // console.log(`i: ${i} | seriesCloneCPU.length: ${seriesCloneCPU.length}`);
                if (i < seriesCloneCPU.length) {
                    playSeries(i);
                }
                else {
                    // Phase B:
                    this.timeout = setTimeout(() => {
                        alert("Timeout!");
                        this.game(true);
                    }, 3000);
                    let seriesPlayer = seriesCloneCPU.slice(0);
                    this.setState({
                        phase: 'B',
                        seriesPlayer
                    });
                }
            }, 1000)
        }
        playSeries();

        let leftNumber;
        let rightNumber;
        if (seriesCloneCPU.length < 10) {
            leftNumber = 0;
            rightNumber = seriesCloneCPU.length
        } else {
            leftNumber = Math.floor(seriesCloneCPU.length / 10);
            rightNumber = seriesCloneCPU.length % 10;
        }

        this.setState({
            seriesCPU: seriesCloneCPU,
            leftNumber,
            rightNumber
        });
    }

    // Do the corresponding animation:
    animate(n) {
        this.setState({
            crystalPlaying: n,
            delay: true
        });
        setTimeout(() => {
            this.setState({
                crystalPlaying: null,
                delay: false
            });
        }, 416);
    }

    divCrystalClick(n) {
        if (this.state.phase === 'B' && !this.state.delay) {
            let playPromise = this.state[`crystal${n}`].play();
            if (playPromise !== undefined) {
                playPromise.catch(error => {});
            }

            clearTimeout(this.timeout);
            this.animate(n);

            let seriesPlayerClone = this.state.seriesPlayer.slice(0);
            if (n === seriesPlayerClone[0]) {
                seriesPlayerClone.shift();
                this.setState({ seriesPlayer: seriesPlayerClone });
                if (seriesPlayerClone.length === 0) {
                    this.setState({ phase: 'C'});
                    setTimeout(() => this.game(), 1000);
                }
            } else {
                this.setState({phase: 'C'}, () => {
                    alert("Wrong!");
                    this.game(true);
                });
            }
        }
    }

    btnStrictClick() {
        this.setState(
            (prevState) => ({
                strict: !prevState.strict,
            })
        );
    }

    btnToggleClick() {
        clearTimeout(this.timeout);
        clearTimeout(this.playSoundsTimeout);
        this.setState(
            (prevState) => ({
                isOn: !prevState.isOn,
                seriesCPU: [],
                seriesPlayer: [],
                leftNumber: 0,
                rightNumber: 0,
                phase: 'none'
            })
        );
    }

    componentDidMount() {
        this.setState({
            crystal1: new Audio('/audio/simonSound1.mp3'),
            crystal2: new Audio('/audio/simonSound2.mp3'),
            crystal3: new Audio('/audio/simonSound3.mp3'),
            crystal4: new Audio('/audio/simonSound4.mp3')
        });
    }

    componentWillUnmount() {
        this.setState({
            crystal1: null,
            crystal2: null,
            crystal3: null,
            crystal4: null,
            leftNumber: 0,
            rightNumber: 0,
            strict: false,
            isOn: false,
            seriesCPU: [],
            seriesPlayer: [],
            crystalPlaying: null,
            delay: false,  // indication that a sound is playing
            phase: 'none' // 'A', 'B', 'C', 'none'
        });
        clearTimeout(this.timeout);
    }

    render() {
        const displayNumber = (in_digit) => {
            const addDivs = (...args) => {
                let i = 0;
                return args.map(ele => {
                    if (ele === "middle") {
                        return <div key={i++} className={ele}></div>;
                    } else {
                        return <div key={i++} className={`${ele} section`}></div>;
                    }
                });
            };
            switch (in_digit) {
                case 0:
                    return addDivs("top", "top-right", "top-left", "bottom-right", "bottom-left", "bottom");
                case 1:
                    return addDivs("top-right", "bottom-right");
                case 2:
                    return addDivs("top", "top-right", "middle", "bottom-left", "bottom");
                case 3:
                    return addDivs("top", "top-right", "middle", "bottom-right", "bottom");
                case 4:
                    return addDivs("top-left", "middle", "bottom-right", "top-right");
                case 5:
                    return addDivs("top", "middle", "top-left", "bottom-right", "bottom");
                case 6:
                    return addDivs("top", "top-left", "bottom-right", "bottom-left", "middle", "bottom");
                case 7:
                    return addDivs("top", "top-right", "bottom-right");
                case 8:
                    return addDivs("top", "top-right", "top-left", "middle", "bottom-right", "bottom-left", "bottom");
                case 9:
                    return addDivs("top", "top-right", "top-left", "bottom-right", "middle", "bottom");
            }
        };
        return (
            <div className="parent-1">
                <Card style={{ marginBottom: '20px' }}>
                    <Card.Body>
                        <div className="crystal-container">
                            <div className="crystal-1 crystal" style={this.state.crystalPlaying === 1 ? css.crystal1Playing : {}} onClick={() => this.divCrystalClick(1)}></div>
                            <div className="crystal-2 crystal" style={this.state.crystalPlaying === 2 ? css.crystal2Playing : {}} onClick={() => this.divCrystalClick(2)}></div>
                            <div className="crystal-3 crystal" style={this.state.crystalPlaying === 3 ? css.crystal3Playing : {}} onClick={() => this.divCrystalClick(3)}></div>
                            <div className="crystal-4 crystal" style={this.state.crystalPlaying === 4 ? css.crystal4Playing : {}} onClick={() => this.divCrystalClick(4)}></div>
                        </div>
                        <div className="panel-1">
                            <div className="panel-2">
                                <div className="digital-numbers-container">
                                    <div className="left-number number">
                                        {this.state.isOn ? displayNumber(this.state.leftNumber) : ``}
                                    </div>
                                    <div className="right-number number">
                                        {this.state.isOn ? displayNumber(this.state.rightNumber) : ``}
                                    </div>
                                </div>
                                <Button variant="light" className="start-button" size="lg"
                                        onClick={() => this.btnStartClick()}
                                        disabled={!this.state.isOn || this.state.phase === 'A'}>Start</Button>
                                <div className="toggle-panel">
                                    <div className="toggle-button">
                                        <ToggleButton
                                            onClick={() => this.btnToggleClick()}
                                            value={this.state.isOn} />
                                    </div>
                                    <Button variant="light" className="strict-button" disabled={!this.state.isOn || this.state.phase !== 'none'}
                                        style={this.state.strict ? css.strictButton : {}}
                                        onClick={() => this.btnStrictClick()}>Strict</Button>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <u>Instructions</u>:
                        <ol>
                            <li>Switch ON the game board</li>
                            <li>Press START!</li>
                        </ol>
                        Note: You can switch on the "Strict" button to enable hard mode.
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

const SimonGame = () => {
    React.useEffect(() => {
        document.title = 'Simon Game';
        addBodyMinWidth550();

        return () => {
            removeBodyMinWidth550();
        };
    });

    return (
        <div id="simon-game">
            <h1 className="page-title">Simon Game</h1>
            <MainComponent />
        </div>
    );
}

export default SimonGame;
